import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SectionHeroPage from "../../components/sections/heroPage"
import HeadingAwards from "../../images/svg/inline-svg/heading_awards.svg"
import SectionMediaHalf from "../../components/sections/mediaHalf"
import SectionWinnersMediaPast from "../../components/sections/sectionWinnersMediaPast"
import HeadingWnners from "../../images/svg/inline-svg/heading_wnners.svg"
import HeadingWnnersMobile from "../../images/svg/inline-svg/heading_wnners_mobile.svg"
import SectionWinnersDetails from "../../components/sections/sectionWinnersDetails"
import HeadingAwardsMobile from "../../images/svg/inline-svg/heading_awards_mobile.svg"
import SectionCurrentSponsors from "../../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrs from "../../images/svg/inline-svg/heading_past-spnsrs.svg"
import HeadingPastSpnsrsMobile from "../../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"

const AwardsPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              year
              awardsNominationLink
            }
          }
          headerAwards: file(relativePath: { eq: "headers/hero-awards.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerImageMobile: file(
            relativePath: { eq: "headers/hero-awards-mobile.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 624) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          practiceOfTheYear: file(
            relativePath: { eq: "DoesYourPractice.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerAwards = data.headerAwards.childImageSharp.fluid
        const headerImageMobile = data.headerImageMobile.childImageSharp.fluid
        const practiceOfTheYear = data.practiceOfTheYear.childImageSharp.fluid
        const year = data.site.siteMetadata.year
        const awardsNominationLink = data.site.siteMetadata.awardsNominationLink

        return (
          <Layout>
            <Seo
              title={`Ascend ${year} Awards`}
              location={`https://ascendevent.com/awards/`}
              description="Learn more about how to submit your organization to win prestigious awards from the best physical therapy rehab conference"
            />
            <SectionHeroPage
              heading=""
              eyebrow="Practice and Innovator of the Year"
              subheading="Recognizing the best of the best in rehab therapy.<p style='font-size: 26px; margin-top: 2rem;'>Ascend Awards applications are now closed.</p>"
              textWidth={4}
              mediaMediumOffset={0}
              image={headerAwards}
              sectionHeading={<HeadingAwards />}
              ctaText="Give it a shot"
              mobileSectionHeading={<HeadingAwardsMobile />}
              mobileImage={headerImageMobile}
            />
            <SectionMediaHalf
              image={practiceOfTheYear}
              videoUrl=""
              showVideo={false}
              sectionHeading=""
              textSmallOffset={0}
              textMediumOffset={0}
              textLargeOffset={1}
              textWidthMedium={7}
              mediaSmallOffset={0}
              mediaMediumOffset={0}
              mediaLargeOffset={0}
              imageWidthMedium={4}
              textMediumOrder={2}
              textLargeOrder={2}
              textSmallOrder={2}
              mediaSmallOrder={1}
              mediaMediumOrder={1}
              mediaLargeOrder={1}
              headingTag="h3"
              sectionBody="<h3>Does Your Practice Have What it Takes?</h3><p>
                    Ascend is the ultimate business summit for rehab therapy professionals—so, naturally, we present the ultimate awards for rehab therapy professionals. The Ascend awards recognize two outstanding practices in two different ways. The Innovator of the Year award is presented to a practice that’s a total rehab therapy trailblazer—one that’s executing innovative, unique, and cool new ideas. The Practice of the Year award is presented to an industry leader—a practice that’s making waves and enjoying proven success. One application, two awards.</p>

                    <p>Both awards are open to all outpatient physical, occupational, and/or speech therapy practices. On the application, a representative must designate him or herself as the individual who’s submitting the application on behalf of the practice. This representative will serve as your practice’s point of contact for all future communication regarding the award.</p>

                    <p>You do not need to be a WebPT Member to win, nor does being a WebPT Member increase your chances of winning. While we’ll notify the finalists by July 31, 2024, we will not formally announce the winner until the Ascend 2024 event.</p>"
              ctaText="Give it a shot"
            />
            <SectionWinnersMediaPast
              sectionHeading={<HeadingWnners />}
              sectionHeadingMobile={<HeadingWnnersMobile />}
            />
            <SectionWinnersDetails ctaText="Give it a shot" />
            <SectionCurrentSponsors />
          </Layout>
        )
      }}
    />
  )
}

export default AwardsPage
