import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Link from "../../utilities/gatsbyLink"

const SectionWinnersDetails = ({
  headingTag: HeadingTag = "p",
  sectionHeading,
  ctaLink,
  ctaText,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          accentDiamond: file(relativePath: { eq: "WInnersAreChosen.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 192) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const accentDiamond = data.accentDiamond.childImageSharp.fluid

        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="small-12 medium-2 cell">
                  <ImageContainer>
                    <Img fluid={accentDiamond} />
                  </ImageContainer>
                </div>
                <div className="small-12 large-4 cell large-offset-1">
                  <ListHeading>
                    Winners are Chosen Based on the Following Criteria:{" "}
                  </ListHeading>
                  <DetailsUnorderedList>
                    <li>Significance of results achieved</li>
                    <li>Inclusion of data to support reported results</li>
                    <li>Practice’s methodology in achieving results</li>
                    <li>
                      Effect of business’s success on the rehab therapy
                      community
                    </li>
                    <li>Practice’s future goals</li>
                  </DetailsUnorderedList>
                  {ctaLink && ctaText && ctaText.length > 0 && (
                    <div className="button-container">
                      <Link to={ctaLink} className="button large">
                        {ctaText}
                      </Link>
                    </div>
                  )}
                </div>
                <div className="small-12 large-5 cell">
                  <ListHeading>Ascend Award Winners Receive:</ListHeading>
                  <DetailsUnorderedList>
                    <li>
                      A WebPT-distributed press release announcing their win and
                      describing their successes;
                    </li>
                    <li>A guest on the Practice Experience Podcast;</li>
                    <li>An award plaque;</li>
                    <li>
                      An Ascend 2024 Innovator of the Year or Practice of the
                      Year logo for use on their practice’s website and
                      marketing materials for up to five years following the
                      event; and
                    </li>
                    <li>Two tickets to Ascend 2025.</li>
                  </DetailsUnorderedList>

                  <FinePrint>
                    For your practice to be eligible to win, at least one
                    practice owner or staff member must attend Ascend 2024 on
                    September 12-14 in Chicago, IL. That attendee must accept
                    the award on the practice’s behalf and share the practice’s
                    success story with the rest of the event attendees during a
                    small speech following the award’s announcement.
                    Additionally, the winning practice consents to WebPT’s use
                    of the practice's name, logo, and details of the award
                    application for marketing purposes.
                  </FinePrint>
                </div>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionWinnersDetails

const StyledSection = styled.section`
  padding: 100px 0 50px;

  .button {
    margin-bottom: 30px;
  }
`
const FinePrint = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
`
const DetailsUnorderedList = styled.ul`
  margin-bottom: 30px;
  margin-top: 30px;
  margin-left: 14px;
`
const ListHeading = styled.h3`
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 640px) {
    font-weight: 400;
    font-size: 20px;
    line-height: 160%;
  }
`
const ImageContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  @media screen and (max-width: 1024px) {
    .gatsby-image-wrapper {
      width: 192px;
      margin: 0 auto;
    }
  }
`
