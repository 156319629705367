import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import VerticalSectionTitle from "./verticalSectionTitle"
import Img from "gatsby-image"
import ReactPlayer from "react-player"

const SectionWinnersMediaPast = ({
  headingTag: HeadingTag = "p",
  sectionHeading,
  sectionHeadingMobile,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          poty2023: file(relativePath: { eq: "awards/2023POTY.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 505) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ioty2023: file(relativePath: { eq: "awards/2023IOTY.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 505) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          poty2022: file(
            relativePath: {
              eq: "awards/20220520-MH1_7897-Ascend2022-Genesis.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ioty2022: file(
            relativePath: {
              eq: "awards/20220520-MH1_7891-Ascend2022-Genesis.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          poty2020: file(relativePath: { eq: "dummy/dummy.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ioty2020: file(relativePath: { eq: "dummy/dummy.png" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          poty2019: file(
            relativePath: { eq: "awards/20190921-080949-Ascend2019.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ioty2019: file(
            relativePath: { eq: "awards/20190921-081032-Ascend2019_Edit.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          poty2017: file(
            relativePath: { eq: "awards/lefferts_20170930_9404.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          ioty2018: file(
            relativePath: {
              eq: "awards/Ascend2018-20180928-153414-0124_Edit.png"
            }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          poty2016: file(
            relativePath: { eq: "awards/20160910_lefferts_4806.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          poty2015: file(
            relativePath: { eq: "awards/20150919_lefferts_6642.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 330) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const poty2023 = data.poty2023.childImageSharp.fluid
        const ioty2023 = data.ioty2023.childImageSharp.fluid
        const poty2022 = data.poty2022.childImageSharp.fluid
        const ioty2022 = data.ioty2022.childImageSharp.fluid
        const poty2019 = data.poty2019.childImageSharp.fluid
        const ioty2019 = data.ioty2019.childImageSharp.fluid
        const poty2017 = data.poty2017.childImageSharp.fluid
        const ioty2018 = data.ioty2018.childImageSharp.fluid
        const poty2016 = data.poty2016.childImageSharp.fluid
        const poty2015 = data.poty2015.childImageSharp.fluid

        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <StyledVerticalSectionTitle>
                  <VerticalSectionTitle
                    svg={sectionHeading}
                    mobileSvg={sectionHeadingMobile}
                  />
                </StyledVerticalSectionTitle>
                <div className="small-12 medium-10 cell  medium-offset-1">
                  <div className="grid-container full">
                    <div className="grid-x grid-padding-x">
                      <AwardCardLarge className="small-12 medium-6 cell">
                        <ImageContainer>
                          <Img fluid={poty2023} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2023 Practice of the Year Winner
                          </AwardTitle>
                          <AwardWinner>
                            Rehabilitation and Performance Institute
                          </AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/qa-with-the-2022-ascend-practice-of-the-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardLarge>
                      <AwardCardLarge>
                        <ImageContainer>
                          <Img fluid={ioty2023} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2023 Innovator of the Year Winner
                          </AwardTitle>
                          <AwardWinner>Blaze Physio</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/qa-with-ascend-2023-innovator-of-the-year"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardLarge>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={poty2022} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2022 Practice of the Year Winner
                          </AwardTitle>
                          <AwardWinner>Dynamix Physical Therapy</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/qa-with-the-2022-ascend-practice-of-the-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.prnewswire.com/news-releases/dynamix-physical-therapy-and-rehab-2-perform-earn-top-awards-at-ascend-2022-301567071.html"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardSmall>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={ioty2022} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2022 Innovator of the Year Winner
                          </AwardTitle>
                          <AwardWinner>Rehab 2 Perform</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/qa-with-the-2022-ascend-innovator-of-the-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.prnewswire.com/news-releases/dynamix-physical-therapy-and-rehab-2-perform-earn-top-awards-at-ascend-2022-301567071.html"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardSmall>
                      <AwardVideoCardSmall>
                        <VideoContainer>
                          <ReactPlayer
                            url="https://share.vidyard.com/watch/c7ZJfdgdQY7hrQwj8uCGjx"
                            width="100%"
                            height="200px"
                            controls={true}
                          />
                        </VideoContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2020 Practice of the Year Winner
                          </AwardTitle>
                          <AwardWinner>Spear Physical Therapy</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/qa-with-the-2020-ascend-practice-of-the-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.businesswire.com/news/home/20201117005023/en/SPEAR-Physical-and-Occupational-Therapy-and-SymFit-Physical-Therapy-and-Fitness-Receive-Top-Awards-at-Ascend-2020"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardVideoCardSmall>
                      <AwardVideoCardSmall>
                        <VideoContainer>
                          <ReactPlayer
                            url="https://share.vidyard.com/watch/sY8WqRVTPwMCxZp8JSbBBQ"
                            width="320px"
                            height="200px"
                            controls={true}
                          />
                        </VideoContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2020 Innovator of the Year Winner
                          </AwardTitle>
                          <AwardWinner>SymFit TV</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/qa-with-the-2020-ascend-innovator-of-the-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.businesswire.com/news/home/20201117005023/en/SPEAR-Physical-and-Occupational-Therapy-and-SymFit-Physical-Therapy-and-Fitness-Receive-Top-Awards-at-Ascend-2020"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardVideoCardSmall>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={poty2019} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2019 Practice of the Year Winner
                          </AwardTitle>
                          <AwardWinner>
                            MedStar Rehabilitation Network
                          </AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/post/qa-with-the-2019-ascend-practice-of-the-year-winner/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.businesswire.com/news/home/20191004005090/en/MedStar-Health-Physical-Therapy-Summit-Physical-Therapy"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardSmall>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={ioty2019} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2019 Innovator of the Year Winner
                          </AwardTitle>
                          <AwardWinner>
                            Summit Physical Therapy, Inc.
                          </AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/post/qa-with-the-2019-ascend-innovator-of-the-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.businesswire.com/news/home/20191004005090/en/MedStar-Health-Physical-Therapy-Summit-Physical-Therapy"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardSmall>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={ioty2018} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2018 Innovator of the Year Winner
                          </AwardTitle>
                          <AwardWinner>MTI Physical Therapy</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.webpt.com/blog/post/qa-with-the-2018-ascend-practice-of-the-year-winner"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Q&A
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.businesswire.com/news/home/20181009006091/en/MTI-Physical-Therapy-Named-2018-Ascend-Practice"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardSmall>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={poty2017} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2017 Practice of the Year Winner
                          </AwardTitle>
                          <AwardWinner>OSI Physical Therapy</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://osipt.com/31505-2/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              View Announcement
                            </a>{" "}
                            |{" "}
                            <a
                              href="https://www.pivotphysicaltherapy.com/news/pivot-physical-therapy-named-webpt-ascend-2016-practice-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardSmall>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={poty2016} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2016 Practice of the Year Winner
                          </AwardTitle>
                          <AwardWinner>Pivot Physical Therapy</AwardWinner>
                          <AwardPressRelease>
                            <a
                              href="https://www.pivotphysicaltherapy.com/news/pivot-physical-therapy-named-webpt-ascend-2016-practice-year/"
                              target="_blank"
                              rel="noreferrer nofollow"
                            >
                              Read Press Release
                            </a>
                          </AwardPressRelease>
                        </AwardMeta>
                      </AwardCardSmall>
                      <AwardCardSmall>
                        <ImageContainer>
                          <Img fluid={poty2015} />
                        </ImageContainer>
                        <AwardMeta>
                          <AwardTitle>
                            2015 Practice of the Year Winner
                          </AwardTitle>
                          <AwardWinner>Atlantic Physical Therapy</AwardWinner>
                        </AwardMeta>
                      </AwardCardSmall>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionWinnersMediaPast

const StyledSection = styled.section`
  padding: 100px 0 50px;
`
const StyledVerticalSectionTitle = styled.div``
const AwardTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  margin-bottom: 0;
`
const AwardWinner = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
`
const AwardPressRelease = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  margin-bottom: 0;
`
const AwardCardLarge = styled.div.attrs({
  className: "small-12 medium-6 cell",
})`
  margin-bottom: 30px;

  .gatsby-image-wrapper {
    margin-bottom: 30px;
  }
`
const AwardCardSmall = styled.div.attrs({
  className: "small-12 medium-6 large-4 cell",
})`
  margin-bottom: 30px;

  .gatsby-image-wrapper {
    margin-bottom: 30px;
    max-height: 207px;
  }
`
const AwardVideoCardSmall = styled.div.attrs({
  className: "small-12 medium-6 large-4 cell",
})`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .gatsby-image-wrapper {
    margin-bottom: 30px;
    max-height: 207px;
  }
`
const AwardMeta = styled.div``
const ImageContainer = styled.div``
const VideoContainer = styled.div`
  > * {
    //margin-bottom: 30px;
    //height: 207px;
    //max-height: 207px;
  }
`
